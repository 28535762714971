/*
 * IMPORTS
 */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");


/*
 * CSS
 */


.dateRangePicker {
  .react-calendar {
    border: unset;
    background-color: transparent;
    font-family: "DM Sans", sans-serif;
  }

  .react-calendar__navigation__prev2-button {
    display: none;
  }

  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation {
    align-items: center;
  }

  abbr[title] {
    border-bottom: none;
    text-decoration: unset;
    text-decoration: unset;
    text-decoration: unset !important;
  }

  .react-calendar__navigation__prev-button {
    background-color: #4318ff !important;
    border-radius: 10px;
    min-width: 34px !important;
    height: 34px !important;
    color: white;
  }

  .react-calendar__navigation__next-button {
    background-color: #4318ff !important;
    border-radius: 10px;
    min-width: 34px !important;
    width: 34px !important;
    height: 34px !important;
    color: white;
  }

  .react-calendar__navigation__label {
    font-weight: 700 !important;
    font-size: 18px;
  }

  .react-calendar__navigation__label:hover,
  .react-calendar__navigation__label:focus {
    background-color: unset !important;
    border-radius: 10px;
  }

  .react-calendar__tile {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
    height: 34px !important;
    color: #1b2559;
  }

  .react-calendar__month-view__weekdays {
    background-color: #f4f7fe;
    border-radius: 10px;
    margin-bottom: 6px;
  }

  .react-calendar__tile--now,
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background-color: #f4f7fe;
    border-radius: 10px;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #b0bbd5;
  }

  .react-calendar__tile--active,
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #4318ff;
    border-radius: 10px;
    color: white;
  }

  .react-calendar__tile--range,
  .react-calendar__tile--range:enabled:hover,
  .react-calendar__tile--range:enabled:focus {
    background: #f4f7fe;
    color: #4318ff;
    border-radius: 0px;
  }

  .react-calendar__tile--rangeStart,
  .react-calendar__tile--rangeStart:enabled:hover,
  .react-calendar__tile--rangeStart:enabled:focus {
    background: #4318ff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: white;
  }

  .react-calendar__tile--rangeEnd,
  .react-calendar__tile--rangeEnd:enabled:hover,
  .react-calendar__tile--rangeEnd:enabled:focus {
    background: #4318ff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;
  }

  .rdrDateRangeWrapper {
    background-color: #fff;
  }

  .rdrMonthAndYearWrapper {
    background-color: #3C10F6;
    padding: 10px 0;
  }

  .rdrMonthAndYearPickers {
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    gap: 10px;
  }

  .rdrNextPrevButton.rdrNextButton i {
    border-width: 5px;
    border-color: transparent transparent transparent #FFFFFF;
  }

  .rdrMonthAndYearPickers select {
    padding: 0;
    color: #FFF;
    font-size: 17px;
    background-image: none;
  }

  .rdrWeekDays {
    font-size: 16px;
  }

  .rdrDayDisabled {
    width: 44px;
    height: 44px;
    background-color: #FFF;
  }

  .rdrWeekDay {
    width: 44px;
    height: 44px;
    margin-top: 10px;
    font-size: 15px;
  }

  .rdrStartEdge {
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
  }

  .rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
  }

  .rdrDayNumber {
    font-weight: 500;
    font-size: 13px;
  }

  .chakra-button.chakra-menu__menu-button {
    & svg {
      width: 18px;
      height: 18px;
    }
  }

  &.asFatButton .chakra-button.chakra-menu__menu-button {
    background-color: var(--chakra-colors-gray-100);
    border-radius: 10px;
    padding: 22px;
    font-size: 13px;
    font-weight: 500;
    width: 100%;

    & svg {
      width: 20px;
      height: 20px;
    }
  }
}